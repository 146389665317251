import { useQuery } from '@apollo/client';
/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter, useRouter } from 'next/router';
import { DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import omit from 'lodash.omit';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import {
    faBars,
    faBellOn,
    faCity,
    faHeart,
    faHome,
    faComments,
    faUser,
    faUserCog,
    faCog,
    faSignOutAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faBars as faBarsDuo } from '@fortawesome/pro-duotone-svg-icons';

import { Media } from '~/Media';
import PRIMARY from '~/constants/colors';

import NavLink from '~/components/ActiveLink';
import LoadingDots from '~/components/LoadingDots';
import IsAuthenticated from '~/components/IsAuthenticated';
import ConsumerHeaderAvatarWithMenu from '~/components/ConsumerHeaderAvatarWithMenu';
// import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';

import Au from './au.svg';
import Sg from './sg.svg';
import Us from './us.svg';
import logo from '~/assets/logo.png';
// import icon from '~/assets/logo_only.png';

import CurrentConsumer from './Operations.graphql';

import styles from './PublicHeader.module.scss';

const propertySearchMenu = [
    {
        link: '/buy',
        text: 'Buy',
    },
    {
        link: '/rent',
        text: 'Rent',
    },
    {
        link: '/sold',
        text: 'Sold',
    },
    // {
    //     link: '/suburb-profile/',
    //     text: 'Suburbs',
    // },
];

const searchMenu = [
    // {
    //     link: '/contact-agent',
    //     text: 'Agents',
    // },
    {
        link: '/mortgagebroker',
        text: 'Brokers',
    },
];

const ViewProfileMenu = () => {
    const { data, loading } = useQuery(CurrentConsumer);

    if (loading) return <LoadingDots />;

    const consumer = data.viewer || {};
    const profileId = consumer.profilePageId;
    return (
        <Link href={`/${profileId}`} prefetch={false} className="items-center" style={{ display: 'flex' }}>
            <FA icon={faUser} className="mr-3 text-base" />
            <span>View Profile</span>
        </Link>
    );
};

const PublicHeaderMainMenu = memo(({ isFromSearch, location, textColor }) => {
    const router = useRouter();
    const { query, asPath } = location || {};
    const selectedKeys = [...searchMenu, ...propertySearchMenu].find(({ link }) => asPath.startsWith(link));
    const omittedQuery = omit(query, ['priceMin', 'priceMax']);
    const queryParam = new URLSearchParams(omittedQuery).toString();

    return (
        <div
            // selectedKeys={[selectedKeys ? selectedKeys.link : '']}
            className="flex flex-shrink-0 flex-grow items-center border-0 bg-transparent hover:text-primary-500"
        >
            {propertySearchMenu.map(({ text, link }) => (
                <div
                    key={link}
                    className={`search-type public-menu-item border-b-0 px-2 text-sm lg:px-3 xxl:px-4 ${textColor}`}
                    style={{ borderWidth: 0 }}
                >
                    <NavLink
                        prefetch={false}
                        href={isFromSearch ? `${link}/search${queryParam ? `?${queryParam}` : ''}` : `${link}#search`}
                    >
                        <a
                            className={clsx(
                                selectedKeys?.text === text
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            )}
                        >
                            {text}
                        </a>
                    </NavLink>
                </div>
            ))}
            <div
                key="/contact-agent"
                className={`border-b-0 px-2 text-sm lg:px-3 xxl:px-5 ${textColor}`}
                style={{ borderWidth: 0 }}
            >
                <NavLink href="/contact-agent" prefetch={false}>
                    <a
                        className={`${
                            router.asPath === '/contact-agent'
                                ? styles.animatedSelectedLine
                                : `${styles.animatedLine2} text-black`
                        }`}
                    >
                        Agents
                    </a>
                </NavLink>
            </div>
            {searchMenu.map(({ text, link }) => (
                <div
                    key={link}
                    className={` search-type public-menu-item border-b-0 px-2 text-sm lg:px-3 xxl:px-4 ${textColor}`}
                    style={{ borderWidth: 0 }}
                >
                    <NavLink href={`${link}#search`} prefetch={false}>
                        <a
                            className={`${
                                router.asPath === `${link}#search`
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            {text}
                        </a>
                    </NavLink>
                </div>
            ))}
            <div
                key="/channels"
                className={` border-b-0 px-2 text-sm lg:px-3 xxl:px-5 ${textColor}`}
                style={{ borderWidth: 0 }}
            >
                <a
                    href="//channels.realty.com.au"
                    className={`${
                        router.asPath === '/channels'
                            ? styles.animatedSelectedLine
                            : `${styles.animatedLine2} text-black`
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Channels
                </a>
            </div>
        </div>
    );
});

PublicHeaderMainMenu.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.object,
        asPath: PropTypes.string,
    }),
    textColor: PropTypes.string,
};

PublicHeaderMainMenu.defaultProps = {
    location: {
        pathname: '',
        query: {},
        asPath: '',
    },
    textColor: '',
};

const PublicHeaderUserMenu = memo(({ pathname, isAuthenticated, textColor, type, buttonType }) => {
    const router = useRouter();

    return (
        <div
            // selectedKeys={[pathname]}
            className={`${styles.publicMenu} hidden items-center justify-end border-0 bg-transparent md:flex xxl:flex`}
        >
            {isAuthenticated && [
                <div key="home" className={`px-3 xl:px-2.5 ${textColor}`} style={{ borderWidth: 0 }}>
                    <NavLink href="/feed" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/feed'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faHome}
                                // ? inline style to override svg icon size and hover underline position
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="l:-mr-1 mr-0 mt-1 pb-0 text-xl xxl:mr-2 xxl:mt-0"
                            />
                            <span className="hidden xxl:inline">Realty</span>
                        </a>
                    </NavLink>
                </div>,
                <div
                    key="my-inspirations"
                    className={`px-3 xl:px-4 xxl:px-5 ${textColor}`}
                    // className={`items-center  xxl:flex block px-5 ${textColor}`}
                    style={{ borderWidth: 0 }}
                >
                    <NavLink href="/inspirations" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/inspirations'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faHeart}
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="l:-mr-1 mr-0 mt-1 pb-0 text-xl xxl:mr-2 xxl:mt-0"
                            />
                            <span className="hidden xxl:inline">Inspirations</span>
                        </a>
                    </NavLink>
                </div>,
                <div key="my-alerts" className={`px-3 xl:px-4 xxl:px-5 ${textColor}`} style={{ borderWidth: 0 }}>
                    <NavLink href="/alerts" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/alerts'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faBellOn}
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="l:-mr-1 mr-0 mt-1 pb-0 text-xl xxl:mr-2 xxl:mt-0"
                            />
                            <span className="hidden xxl:inline">Alerts</span>
                        </a>
                    </NavLink>
                </div>,
                <div key="/properties" className={`px-3 xl:px-4 xxl:px-5 ${textColor}`} style={{ borderWidth: 0 }}>
                    <NavLink href="/properties" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/properties'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faCity}
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="l:-mr-1 mr-0 mt-1 pb-0 text-xl xxl:mr-2 xxl:mt-0"
                            />
                            <span className="hidden xxl:inline">Properties</span>
                        </a>
                    </NavLink>
                </div>,
                <div
                    key="/chat"
                    className={`public-menu-item px-3 xl:px-4 xxl:px-5 ${textColor}`}
                    style={{ borderWidth: 0 }}
                >
                    <NavLink href="/chat" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/chat'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faComments}
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="l:-mr-1 mr-0 mt-1 pb-0 text-xl xxl:mr-2 xxl:mt-0"
                            />
                            <span className="hidden xxl:inline">Chat</span>
                        </a>
                    </NavLink>
                </div>,
                <div key="3" className="public-menu-item px-3 xl:px-4 xxl:px-5" style={{ borderWidth: 0 }}>
                    <ConsumerHeaderAvatarWithMenu />
                </div>,
            ]}
            {!isAuthenticated && [
                <div key="/inspirations" className={`px-5 text-sm ${textColor}`} style={{ borderWidth: 0 }}>
                    <NavLink href="/inspirations" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/inspirations'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faHeart}
                                // ? inline style to override svg icon size and hover underline position
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="mr-0 mt-0 text-xl xxl:mr-2 xxl:mt-0 "
                            />
                            <span className="hidden xxl:inline">Inspirations</span>
                        </a>
                    </NavLink>
                </div>,
                <div level={2} key="/alerts" className={`px-5 text-sm ${textColor}`} style={{ borderWidth: 0 }}>
                    <NavLink href="/alerts" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/alerts'
                                    ? styles.animatedSelectedLine
                                    : `${styles.animatedLine2} text-black`
                            }`}
                        >
                            <FA
                                icon={faBellOn}
                                style={{ fontSize: '1.35rem', verticalAlign: '-6px' }}
                                className="mr-0 mt-0 text-xl xxl:mr-2 xxl:mt-0"
                            />
                            <span className="hidden xxl:inline">Alerts</span>
                        </a>
                    </NavLink>
                </div>,
                // <Menu.Item
                //     level={2}
                //     key="/sign-up"
                //     className={`text-sm ${textColor}`}
                //     style={{ borderWidth: 0 }}
                // >
                //     <NavLink href="/sign-up">
                //         <a>Join</a>
                //     </NavLink>
                // </Menu.Item>,
                // <Menu.Item
                //     level={2}
                //     key="/login"
                //     className={`text-sm ${textColor}`}
                //     style={{ borderWidth: 0 }}
                // >
                //     <NavLink href="/login">
                //         <a>Login</a>
                //     </NavLink>
                // </Menu.Item>,
            ]}
        </div>
    );
});

PublicHeaderUserMenu.propTypes = {
    pathname: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    textColor: PropTypes.string,
    type: PropTypes.any,
    buttonType: PropTypes.string,
};

PublicHeaderUserMenu.defaultProps = {
    pathname: '/',
    isAuthenticated: false,
    textColor: '',
    type: null,
    buttonType: '',
};

const PublicTopHeaderUserMenu = memo(({ pathname, isAuthenticated }) => {
    const router = useRouter();

    return (
        <div
            // selectedKeys={[pathname]}
            className={`${styles.publicMenu} hidden justify-end border-0 bg-transparent md:flex`}
        >
            {!isAuthenticated && [
                <div level={2} key="/sign-up" className="px-5 pt-0.5 text-sm" style={{ borderWidth: 0 }}>
                    <NavLink href="/sign-up" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/sign-up'
                                    ? styles.animatedSelectedLine2
                                    : `${styles.animatedLine} text-white`
                            }`}
                        >
                            Join
                        </a>
                    </NavLink>
                </div>,
                <div level={2} key="/login" className="px-5 pt-0.5 text-sm" style={{ borderWidth: 0 }}>
                    <NavLink href="/login" prefetch={false}>
                        <a
                            className={`${
                                router.asPath === '/login'
                                    ? styles.animatedSelectedLine2
                                    : `${styles.animatedLine} text-white`
                            }`}
                        >
                            Login
                        </a>
                    </NavLink>
                </div>,
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 rotate-90 transform"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M20 12H4" />
                </svg>,
            ]}
        </div>
    );
});
PublicTopHeaderUserMenu.propTypes = {
    pathname: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    // textColor: PropTypes.string,
    type: PropTypes.any,
    buttonType: PropTypes.string,
};
PublicTopHeaderUserMenu.defaultProps = {
    pathname: '/',
    isAuthenticated: false,
    // textColor: '',
    type: null,
    buttonType: '',
};

const PublicHeaderUserMenuMobile = memo(
    ({ isFromSearch, isAuthenticated, textColor, onLogout, menuIconClassname, router }) => {
        const [visible, setVisible] = useState(false);

        const toggleDrawer = () => {
            setVisible(!visible);
        };

        const onCloseDrawer = () => {
            setVisible(false);
        };

        const { pathname, query, asPath } = router || {};
        const selectedKeys = [...searchMenu, ...propertySearchMenu].find(({ link }) => asPath.startsWith(link));
        const queryParam = new URLSearchParams(query).toString();

        const realtySubDomainMenu = (
            <Menu mode="inline" className={styles.RealtySubDomain}>
                <Menu.SubMenu
                    key="submenu"
                    title={
                        <span className="flex items-center justify-start">
                            <Au className="mr-2" style={{ height: 20 }} alt="au" />
                            <span>Realty AU</span>
                        </span>
                    }
                >
                    <Menu.Item key="au" className="py-3">
                        <NavLink href="/">
                            <a
                                onClick={(event) => event.preventDefault()}
                                style={{
                                    color: PRIMARY,
                                    display: 'flex',
                                }}
                                aria-hidden
                                className="items-center"
                            >
                                <Au className="mr-2" style={{ height: 20 }} alt="au" />
                                <span>Realty AU</span>
                            </a>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="sg" className="py-3">
                        <a
                            href="https://singapore.realty.com.au/"
                            className="items-center"
                            style={{ display: 'flex' }}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-hidden
                        >
                            <Sg className="mr-2 border" style={{ height: 20 }} alt="sg" />
                            <span>Realty SG</span>
                        </a>
                    </Menu.Item>
                    <Menu.Item key="us" className="py-3">
                        <a
                            href="https://realty.com/"
                            className="items-center"
                            style={{ display: 'flex' }}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-hidden
                        >
                            <Us className="mr-2 border" style={{ height: 20 }} alt="us" />
                            <span>Realty US</span>
                        </a>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        );

        const menu = (
            <Menu
                size="small"
                bordered="false"
                selectedKeys={[selectedKeys ? selectedKeys.link : '']}
                style={{ borderRight: 0 }}
                className="mobile-menu-dropdown border-none"
                onSelect={onCloseDrawer}
            >
                <Menu.Item key="/home" className={`px-3 text-sm md:mr-1 md:text-base ${textColor}`}>
                    <NavLink href="/">
                        <a>Home</a>
                    </NavLink>
                </Menu.Item>
                <Menu.Divider key="divider-1" />
                {propertySearchMenu.map(({ text, link }) => (
                    <Menu.Item key={link} className={`px-3 text-sm md:mr-1 md:text-base ${textColor}`}>
                        <NavLink
                            prefetch={false}
                            href={
                                isFromSearch ? `${link}/search${queryParam ? `?${queryParam}` : ''}` : `${link}#search`
                            }
                        >
                            <a>{text}</a>
                        </NavLink>
                    </Menu.Item>
                ))}
                <Menu.Divider key="divider-2" />
                <Menu.Item key="/contact-agent" className={`px-3 text-sm md:mr-1 md:text-base ${textColor}`}>
                    <NavLink href="/contact-agent" prefetch={false}>
                        <a>Agents</a>
                    </NavLink>
                </Menu.Item>
                {searchMenu.map(({ text, link }) => (
                    <Menu.Item key={link} className={`px-3 text-sm md:mr-1 md:text-base ${textColor}`}>
                        <NavLink href={`${link}#search`} prefetch={false}>
                            <a>{text}</a>
                        </NavLink>
                    </Menu.Item>
                ))}
                <Menu.Item key="/channels" className={`px-3 text-sm md:mr-1 md:text-base ${textColor}`}>
                    <a href="//channels.realty.com.au" target="_blank" rel="noopener noreferrer">
                        Channels
                    </a>
                </Menu.Item>
                <Menu.Divider key="divider-3" />
                {isAuthenticated && [
                    <Menu.Item key="dashboard" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/feed" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faHome} className="mr-3 text-base" />
                                <span>Feed</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/inspirations" className={`py-3  text-gray-500 ${textColor}`}>
                        <NavLink href="/inspirations" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faHeart} className="mr-3 text-base" />
                                <span>Inspirations</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/alerts" className={`py-3  text-gray-500 ${textColor}`}>
                        <NavLink href="/alerts" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faBellOn} className="mr-3 text-base" />
                                <span>Alerts</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Divider key="divider-4" />,
                    <Menu.Item key="/properties" className={`py-3  text-gray-500 ${textColor}`}>
                        <NavLink href="/properties" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faCity} className="mr-3 text-base" />
                                <span>Properties</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/chat" className={`py-3  text-gray-500 ${textColor}`}>
                        <NavLink href="/chat" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faComments} className="mr-3 text-base" />
                                <span>Chat</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,
                ]}

                {isAuthenticated && [
                    <Menu.Divider key="divider-5" />,
                    <Menu.Item key="social">
                        <ViewProfileMenu />
                    </Menu.Item>,
                    <Menu.Item key="business-settings">
                        <Link href="/business-settings" className="items-center" style={{ display: 'flex' }}>
                            <FA icon={faCog} className="mr-3 text-base" />
                            <span>Business Settings</span>
                        </Link>
                    </Menu.Item>,
                    <Menu.Item key="settings">
                        <Link href="/settings" className="items-center" style={{ display: 'flex' }}>
                            <FA icon={faUserCog} className="mr-3 text-base" />
                            <span>Profile Settings</span>
                        </Link>
                    </Menu.Item>,
                ]}

                {!isAuthenticated && [
                    <Menu.Item key="/login" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/login" prefetch={false}>
                            <a>Login</a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/sign-up" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/sign-up" prefetch={false}>
                            <a>Sign Up</a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/agency-sign-up" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/agency-sign-up" prefetch={false}>
                            <a>Agency Sign Up</a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/broker-sign-up" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/broker-sign-up" prefetch={false}>
                            <a>Broker Sign Up</a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Item key="/agent-sign-up" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/agent-sign-up" prefetch={false}>
                            <a>Agent Sign Up</a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Divider key="divider-6" />,
                    <Menu.Item key="/inspirations" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/inspirations" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faHeart} className="mr-3 text-base" />
                                <span>Inspirations</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,

                    <Menu.Item key="/alerts" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/alerts" prefetch={false}>
                            <a className="items-center" style={{ display: 'flex' }}>
                                <FA icon={faBellOn} className="mr-3 text-base" />
                                <span>Alerts</span>
                            </a>
                        </NavLink>
                    </Menu.Item>,
                    <Menu.Divider key="divider-7" />,
                ]}

                {isAuthenticated && [
                    <Menu.Divider key="divider-8" />,
                    <Menu.Item key="/logout" className={`py-3 text-gray-500 ${textColor}`}>
                        <NavLink href="/">
                            <a onClick={onLogout} aria-hidden>
                                <FA icon={faSignOutAlt} className="mr-3 text-base" />
                                Logout
                            </a>
                        </NavLink>
                    </Menu.Item>,
                ]}
            </Menu>
        );

        return (
            <>
                <div className="flex h-full w-full items-center justify-between">
                    <NavLink href="/">
                        <a className="ml-4 ">
                            <div className="flex items-center py-3">
                                <Image src={logo} alt="Realty.com.au" width={170} height={42} layout="intrinsic" />
                            </div>
                        </a>
                    </NavLink>

                    <Button
                        type="link"
                        // eslint-disable-next-line max-len
                        className={`h-full border-0 bg-transparent hover:bg-transparent active:bg-transparent ${menuIconClassname}`}
                        onClick={toggleDrawer}
                    >
                        <FA
                            icon={visible ? faBarsDuo : faBars}
                            style={{ fontSize: '1.35rem' }}
                            className={`mr-2 transition ${visible ? 'text-primary-500' : 'text-primary-900'}`}
                        />
                    </Button>
                </div>

                <Drawer
                    placement="right"
                    height={null}
                    onClose={onCloseDrawer}
                    visible={visible}
                    maskClosable
                    width="100%"
                    className={`${styles.publicMobileMenuDrawer} text-right`}
                >
                    <>
                        {menu}
                        {realtySubDomainMenu}
                    </>
                </Drawer>
            </>
        );
    }
);

PublicHeaderUserMenuMobile.propTypes = {
    isAuthenticated: PropTypes.bool,
    textColor: PropTypes.string,
    onLogout: PropTypes.func,
    menuIconClassname: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.object,
        asPath: PropTypes.string,
    }),
};

PublicHeaderUserMenuMobile.defaultProps = {
    isAuthenticated: false,
    textColor: '',
    onLogout: () => {},
    menuIconClassname: '',
    router: {
        pathname: '',
        query: {},
        asPath: '',
    },
};

const PublicHeader = memo(({ fixedHeader, router }) => {
    const { pathname, query } = router || {};
    const darkHeaderPages = [].includes(pathname);
    const logoImg = logo;
    const menuIcon = 'text-gray-700';
    const textColor = '';
    const bgColor = 'bg-white';
    const buttonType = 'primary';
    const menuShadow = (pathname.indexOf('/agency/') >= 0 || pathname.indexOf('/agent/') >= 0) && 'shadow-sm';

    const isFromSearch = ['/buy/search', '/rent/search', '/sold/search', '/property/search'].includes(pathname);

    const menu = (
        <Menu theme="dark" selectedKeys={['AU']} style={{ backgroundColor: '#25435d' }}>
            <Menu.Item key="AU" className="flex">
                <div className="flex w-full items-center">
                    <Au className="mr-2 w-6" alt="au" />
                    <span>Australia</span>
                </div>
            </Menu.Item>
            <Menu.Item key="SG" className=" flex">
                <a
                    href="https://singapore.realty.com.au/"
                    target="_blank"
                    style={{ color: '#fff' }}
                    alt="SG"
                    rel="noopener noreferrer"
                >
                    <div className="flex-between flex w-full items-center">
                        <Sg className="mr-2 w-6" alt="sg" />
                        <span>Singapore</span>
                    </div>
                </a>
            </Menu.Item>
            <Menu.Item key="US" className=" flex">
                <a
                    href="https://realty.com/"
                    target="_blank"
                    style={{ color: '#fff' }}
                    alt="US"
                    rel="noopener noreferrer"
                >
                    <div className="flex-between flex items-center">
                        <Us className="mr-2 w-6" alt="us" />
                        <span>United States</span>
                    </div>
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <IsAuthenticated>
            {({ isAuthenticated, logoutUser, type }) => {
                const onClickLogout = () => {
                    logoutUser();
                    router.replace('/');
                };

                return (
                    <header
                        className={clsx(
                            { [styles.fixedTopHeader]: fixedHeader },
                            `header border-gray-400 shadow ${bgColor} z-20 ${menuShadow} px-0`
                        )}
                        style={{ lineHeight: 2 }}
                    >
                        {/* <header
                        className={clsx(
                            { styles['fixedHeader'] : fixedHeader},
                            `${styles.header} border-gray-400 shadow ${bgColor} z-20 ${menuShadow} px-0`
                        )}
                        style={{ lineHeight: 2 }}
                    > */}
                        <Media lessThan="lg">
                            <PublicHeaderUserMenuMobile
                                router={router}
                                isAuthenticated={isAuthenticated}
                                textColor={textColor}
                                type={type}
                                onLogout={onClickLogout}
                                menuIconClassname={menuIcon}
                                isFromSearch={isFromSearch}
                            />
                        </Media>
                        <Media greaterThanOrEqual="lg">
                            <div className="w-full">
                                <div
                                    // eslint-disable-next-line max-len
                                    className={`${styles.TopHeader} flex items-center justify-end sm:pl-4 md:px-12 md:py-1`}
                                >
                                    <PublicTopHeaderUserMenu
                                        pathname={router.pathname}
                                        isAuthenticated={isAuthenticated}
                                        textColor={textColor}
                                        type={type}
                                        buttonType={buttonType}
                                    />
                                    <Dropdown
                                        className="ml-3 shrink-0 text-white"
                                        overlay={menu}
                                        trigger={['click', 'hover']}
                                    >
                                        <Button
                                            ghost
                                            style={{ paddingTop: '0.5rem' }}
                                            className="flex w-28 items-center border-none pt-1"
                                        >
                                            <Au className="mr-2 w-6" alt="au" />
                                            <span className="text-lg font-semibold">AU</span>
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </div>
                                {/* eslint-disable-next-line max-len */}
                                <div className="flex flex-wrap items-center justify-between bg-white py-3 sm:pl-4 md:px-8">
                                    <div className="logo-cont flex-no-grow">
                                        <NavLink href="/">
                                            <a className="inline-block flex flex-col items-center">
                                                <Image
                                                    className="logo inline-block h-10"
                                                    src={logoImg}
                                                    alt="Realty.com.au"
                                                    width={170}
                                                    height={42}
                                                    layout="intrinsic"
                                                />
                                            </a>
                                        </NavLink>
                                    </div>
                                    <div className="flex shrink-0 grow items-center justify-end">
                                        <div>
                                            <PublicHeaderMainMenu
                                                isFromSearch={isFromSearch}
                                                location={router}
                                                textColor={textColor}
                                            />
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 rotate-90 transform"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                className="bg-gray-500"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M20 12H4"
                                            />
                                        </svg>
                                        <div>
                                            <PublicHeaderUserMenu
                                                pathname={router.pathname}
                                                isAuthenticated={isAuthenticated}
                                                textColor={textColor}
                                                type={type}
                                                buttonType={buttonType}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Media>
                    </header>
                );
            }}
        </IsAuthenticated>
    );
});

PublicHeader.propTypes = {
    fixedHeader: PropTypes.bool,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.object,
        replace: PropTypes.func,
    }),
};

PublicHeader.defaultProps = {
    fixedHeader: false,
    router: {
        pathname: '',
        query: {},
        replace: () => {},
    },
};

export default withRouter(PublicHeader);
