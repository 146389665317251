import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faUser, faUserCog } from '@fortawesome/pro-regular-svg-icons';
import { CurrentConsumer } from './Operations.graphql';
import { logoutUser } from '~/actions';
import PRIMARY from '~/constants/colors';
import './ConsumerHeaderAvatarWithMenu.module.scss';

const LogoutButton = ({ isAuthenticated, onLogoutUser }) =>
    isAuthenticated && (
        <div className="flex items-center" onClick={onLogoutUser} aria-hidden>
            <FA icon={faSignOutAlt} className="mr-2 text-base text-primary-500" />
            <span>Logout</span>
        </div>
    );

const LogoutButtonWithState = compose(
    withApollo,
    withRouter,
    connect(
        (state) => ({ isAuthenticated: state.auth.isAuthenticated }),
        (dispatch, { client, router }) => ({
            onLogoutUser() {
                client.resetStore();
                router.replace('/');
                dispatch(logoutUser());
            },
        })
    )
)(LogoutButton);

class ConsumerHeaderAvatarWithMenu extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            loading: PropTypes.bool,
            viewer: PropTypes.object,
        }),
    };

    static defaultProps = {
        data: {
            loading: false,
            viewer: {},
        },
    };

    render() {
        const {
            data: { loading, viewer },
        } = this.props;

        if (loading) {
            return (
                <Avatar
                    style={{
                        color: PRIMARY,
                        backgroundColor: '#fff',
                        border: `1px solid ${PRIMARY}`,
                        width: 35,
                        height: 35,
                    }}
                    icon={<UserOutlined />}
                    alt="avatar"
                    className="loading-avatar user-avatar mr-2"
                />
            );
        }

        const { initials, profilePicture } = viewer || {};

        const menu = (
            <Menu className="ConsumerAvatarMenu" selectedKeys={[]} style={{ width: 200 }}>
                <Menu.Item key="social">
                    <Link href={`/${viewer?.profilePageId}`} className="items-center" style={{ display: 'flex' }}>
                        <FA icon={faUser} className="mr-2 text-base text-primary-500" />
                        <span>View Profile</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="business-settings">
                    <Link href="/business-settings" className="items-center" style={{ display: 'flex' }}>
                        <FA icon={faCog} className="mr-2 text-base text-primary-500" />
                        <span>Business Settings</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="settings">
                    <Link href="/settings" className="items-center" style={{ display: 'flex' }}>
                        <FA icon={faUserCog} className="mr-2 text-base text-primary-500" />
                        <span>Profile Settings</span>
                    </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="logout">
                    <LogoutButtonWithState />
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown
                trigger={['click']}
                overlay={menu}
                placement="bottomRight"
                className="ConsumerHeaderAvatarWithMenu flex items-center"
            >
                <span className="cursor-pointer">
                    <div>
                        <Avatar src={profilePicture} alt="avatar">
                            {initials}
                        </Avatar>
                    </div>
                </span>
            </Dropdown>
        );
    }
}

export default graphql(CurrentConsumer)(ConsumerHeaderAvatarWithMenu);
